.visually-hidden{
	display: none;
}
.main_slider{
	overflow: hidden;
}
.main_slider .carousel-inner img{

	border-radius: 10px;
}
span.hr{
	border: 1px solid #3333;
	margin: 10px 10px;
	display: block;
}
.dashb_icons.custom{

}

.dashb_icons.custom ul {
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 10px;
}
.dashb_icons.custom li {
    margin: 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 2px 2px 10px 0 #00000021;
}
.dashb_icons.custom li a.new {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 10px;
    justify-content: space-between;
}
.dashb_icons.custom li a.new span{
	margin: 0;
}

.dashb_icons.custom li a.new svg {
    margin: 0; 
    width: 20px;
}
.breaking {
    background: #17a2b8;
    padding: 16px 8px;
    z-index: 1;
    /* position: absolute; */
    left: 0;
    top: 0;
    bottom: 0;
}
.overflow-hide{
	overflow: hidden;
}